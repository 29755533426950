<template>
    <div class="al-content">
        <div class="content-top">
            <div class="content-top clearfix">
                <h1 class="al-title ">Sales Forecast</h1>
            </div>
        </div>

        <div class="row ">

            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-body">
                        <div class="dropdown">
                            <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                {{fileName}}
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li class="dropdown-item"
                                    v-for="item in data" :key="item.Id">
                                    <a href="javascript:;" @click="doViewProduct(item.Id,item.SelectedDate,`${item.FileName} - ${formatDate(item.SelectedDate,true)} - by ${item.CreatedBy}`)">{{item.FileName}} - {{formatDate(item.SelectedDate,true)}} - by {{item.CreatedBy}}</a>
                                </li>

                            </ul>
                        </div>


                        <div class="mt-3">
                            <div class="btn-group me-3" v-show="fileId!=0">
                                <button type="button" class="btn btn-primary" @click="showOptions=true">View Forecast</button>
                            </div>
                            <div class="btn-group" v-show="fileId!=0&&checkFullAcl('Inventory/ViewFile')">
                                <button type="button" class="ml-3 btn btn-danger " @click="changeView('product')">View File</button>
                            </div>
                        </div>
                        <div class="mt-3" v-show="isViewForecast">
                            <label class="checkbox-inline custom-checkbox nowrap">
                                <input type="checkbox" @click="forecastFilter='both'" :checked="forecastFilter=='both'?'true':null">
                                <span>Both</span>
                            </label>
                            <label class="checkbox-inline custom-checkbox nowrap">
                                <input type="checkbox" @click="forecastFilter='extra'" :checked="forecastFilter=='extra'?'true':null">
                                <span>Extra</span>
                            </label>
                            <label class="checkbox-inline custom-checkbox nowrap">
                                <input type="checkbox" @click="forecastFilter='shortage'" :checked="forecastFilter=='shortage'?'true':null">
                                <span>Need to Order</span>
                            </label>
                        </div>
                        <div class="mt-2">
                            <div style="text-align:center;font-size:24px;font-weight:bold;" v-show="isViewForecast">
                                Forecast for Day 1 of Month {{getMonthName(monthSelected.month)}} {{
monthSelected.year
                                }}
                            </div>
                            <table v-show="checkFullAcl('Product/View')&&isViewProduct" class="table table-hover table-bordered" id="datatableProduct">
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th id="columnName">Name</th>
                                        <th>Supplier</th>                                        
                                        <th>Region</th>
                                        <th>Color</th>
                                        <th>RegularSold</th>
                                        <th>PeakSold</th>
                                        <th>Inventory <br /> (Tồn Kho)</th>
                                        <th>Updated By</th>
                                    </tr>
                                    
                                </thead>
                                <tbody>
                                    <tr v-for="item in dataProduct" :key="item.id" :style="{'background-color':item.Remain<=0?'#ff000021 !important':'inherit'}">
                                        <td @click="ViewHistory(item.Code,item.Name)">
                                            <a href="javascript:void(0);"
                                               class="editable-click"
                                               data-bs-toggle="tooltip" data-bs-placement="top"
                                               title="View History">
                                                {{item.Code}}
                                            </a>
                                        </td>
<td style="width:50%">{{item.Name}}</td>
                                        
                                        <td>{{item.Catalog}}</td>
                                        <td>{{item.SubCatalog1}}</td>
                                        <td>{{item.SubCatalog2}}</td>
                                        <td>{{formatNumber(item.RegularSold)}}</td>
                                        <td>{{formatNumber(item.PeakSold)}}</td>
                                        <td>{{formatNumber(item.Remain)}}</td>
                                        <td>{{item.UpdatedBy}}</td>
                                    </tr>
                                </tbody>
                            </table>


                            <table v-show="isViewForecast" class="table table-hover table-bordered" id="datatableForecast">
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th id="columnName">Name</th>
                                        <th>Supplier</th>
                                        <th>Region</th>
                                        <th>Color</th>
                                        <th>Inventory <br /> (Tồn Kho)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in dataForecast" :key="item.id"  :style="{'background-color':item.Remain<=0?'#ff000021 !important':'inherit'}">
                                        <td @click="ViewHistory(item.Code,item.Name)">
                                            <a href="javascript:void(0);"
                                               class="editable-click"
                                               data-bs-toggle="tooltip" data-bs-placement="top"
                                               title="View History">
                                                {{item.Code}}
                                            </a>
                                        </td>
                                        <td style="width:50%">{{item.Name}}</td>                                       
                                        <td>{{item.Catalog}}</td>
                                        <td>{{item.SubCatalog1}}</td>
                                        <td>{{item.SubCatalog2}}</td>
                                        <td v-html="showRemain(item.Remain)"></td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div v-show="showOptions"
             class="modal fade show" aria-hidden="false" aria-modal="true" role="dialog" style="background-color: rgba(0, 0, 0, 0.5); opacity: 1; display: block; padding-right: 0px;" >
            <div class="modal-dialog modal-sm" role="document" style="transform: translate(0px, 0px);">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Selected a Month </h5>
                        <button class="btn-close" @click="showOptions=false" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        Select Day 1 of Month
                        <Datepicker v-model="monthSelected" dark monthPicker class="mb-3" autoApply v-bind="datePickerProperties">
                            <template #calendar-header="{ index, day }">
                                <div :class="index === 5 || index === 6 ? 'calenda-red-color' : ''">
                                    {{ day }}
                                </div>
                            </template>
                        </Datepicker>
                    </div>
                    <!--<div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="doForecast">OK</button>
                        <button type="button" class="btn btn-danger" @click="showOptions=false"><i class="ion-close"></i> Cancel</button>
                    </div>-->
                </div>
            </div>
        </div>

    </div>
</template>
<style>
    .dataTables_wrapper .dataTables_paginate .paginate_button{
        color:white!important;
    }
    
</style>
<script>
    //https://therichpost.com/vue-3-datatable-with-export-buttons-print-csv-copy-with-dynamic-data/
    //Datatable Modules
    import "datatables.net-dt/js/dataTables.dataTables"
    import "datatables.net-dt/css/jquery.dataTables.min.css"
    import "datatables.net-buttons/js/dataTables.buttons.js"
    import "datatables.net-buttons/js/buttons.colVis.js"
    import "datatables.net-buttons/js/buttons.flash.js"
    import "datatables.net-buttons/js/buttons.html5.js"
    import "datatables.net-buttons/js/buttons.print.js"
    import "jszip";
    import pdfMake from "pdfmake/build/pdfmake";
    import pdfFonts from "pdfmake/build/vfs_fonts";
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    window.JSZip = require('jszip');
    import $ from 'jquery';
    import _ from 'lodash';
    import 'datatables.net-responsive'

    //========== Datetime picker
    //https://vue3datepicker.com/installation/#global
    import Datepicker from 'vue3-date-time-picker';
    import 'vue3-date-time-picker/dist/main.css'

    import c from '@/mylib/common'
    import { mapState, mapActions } from 'vuex'
    import { ref  } from 'vue';
    export default {
        name: `SalesForecast`,
        data: function () {
            return {
                fileName: 'Select a file',
                fileId: 0,
                selectedDate: new Date(),
                yearRange:[],
                LayoutModel: {},
                data: [],
                dataPeak: [],
                
                dataProduct: [],
                dataForecast: [],
                isViewProduct: true,
                isViewForecast: false,
                forecastFilter: 'both',
                dataTable: null,
                dataTableProduct: null,
                dataTableForecast: null,
                showOptions: false,
                
            }
        },
        
        setup() {
            const monthSelected = ref({
                month: new Date().getMonth()+1,
                year: new Date().getFullYear()
            });
            //const yearRange = [];
            //const _this = this;
            //onMounted(() => {
            //    yearRange.push(_this.selectedDate.getFullYear());
            //    yearRange.push(_this.selectedDate.getFullYear()+5);
            //})
            //const yearRange = computed(() => {
            //    const startRange = _this&& _this.selectedDate || new Date();
            //    return [
            //        startRange.getFullYear(),
            //        startRange.getFullYear()+5
            //    ];
            //});

          

            return {
                monthSelected,
                /*yearRange,*/
            }
        },
        components: { Datepicker },
        computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,
            datePickerProperties: function () {
                
                return {
                    minDate: this.selectedDate,
                    //maxDate: new Date(this.selectedDate.setYear(this.selectedDate.getFullYear()+3)),
                }
            }
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',
                'showModal': 'view/showModal',
                'setGroups': 'user/setGroups',
                
                'call': 'api/call'
            }),
            checkAcl(action) {
                
                return this.$store.state.session.acl.includes(this.$options.name + "/" + action);
            },
            checkFullAcl(router) {
                //console.log("checkfull " + router, this.$store.state.session.acl);
                return this.$store.state.session.acl.includes(router);
            },
            start() {
                this.getAll();
                
            },
            ViewHistory(code,name) {
                if (this.checkFullAcl('Product/ViewHistory'))
                    this.$store.dispatch("view/viewHistory", { code: code,name:name });
            },
            getMonthName(month) {
                const monthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                return monthName[month];
            },
            formatDate(dstr, notime) {
                let d = new Date(dstr);
                if (d == "Invalid Date") return "";
                return c.formatDate(d, notime);
            },
            formatNumber(n) {                
                return c.formatNumber(n);
            },
            showListFile() {                
                $("#ListFileContent").css("display", "block");
            },
            showRemain(n) {                
                const ftmNumber = c.formatNumber(n);
                let html = ftmNumber;
                let sign = "";
                if (n <0) sign = "-";
                if (n <= 0) {
                    html = `<span style="font-size:bold; color: red">${sign}${ftmNumber}</span>`;
                }
                return html;
            },
            doViewProduct(fileid, selectedDate, filename) {
                
                this.fileId = fileid;
                this.fileName = filename;
                this.selectedDate = new Date(selectedDate);
                this.selectedDate.setMonth(this.selectedDate.getMonth()+1);
                this.isViewProduct = true;                
                this.viewProduct(fileid);
                
            },
            doForecast() {
                if (this.monthSelected == null) {
                    this.showModal({ isShow: true, title: 'Error', content: "Please select Month to forecast!", type: 'error' });
                    return;
                }
                this.loading(true);
                

                this.isViewProduct = false;
                this.isViewForecast = true;
                this.showOptions = false;
                this.dataForecast=[];
                const _this = this;

                //calcluate peak   
                let startDate = new Date(this.selectedDate);
                startDate.setMonth(startDate.getMonth() - 1);
                //startDate.setMonth(this.selectedDate.getMonth() + 1); // begin with next month of selectedDate of upload file.                
                let peakCount = 0;
                let regularCount = 0;
                
                while (startDate.getFullYear() * 100 + startDate.getMonth() < this.monthSelected.year * 100 + this.monthSelected.month) {
                
                    if (this.dataPeak[startDate.getMonth()].IsPeak)
                        peakCount++;
                    else
                        regularCount++;
                    startDate.setMonth(startDate.getMonth() + 1);
                }
                
                for (let i = 0; i < this.dataProduct.length; i++) {
                    const item = _.clone(this.dataProduct[i]);
                
                    //calculate remain
                
                    item.Remain -= item.RegularSold * regularCount + item.PeakSold * peakCount;
                
                    //do filter
                    if (_this.forecastFilter == 'extra') {
                        if (item.Remain > 0)
                            _this.dataForecast.push(item);
                    }
                    else if (_this.forecastFilter == 'shortage') {
                        if (item.Remain <= 0) {                            
                            _this.dataForecast.push(item);
                        }
                    } else {
                        _this.dataForecast.push(item);
                    }
                }
                this.changeView('forecast');
                
            },
            async getAll() {
                this.loading(true);
              
                const rs = await this.call({ Action: 'SalesForecast/List' })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    
                    //no permission                    
                    if (rs.Error.includes("Permission Denied")) {
                        window.history.back();
                    }

                } else {                    
                    this.data = rs.Data["lsFile"];
                    
                    //sort by alphabet
                    this.data.sort((a, b) => b.SelectedDate.localeCompare(a.SelectedDate));

                    this.dataPeak = rs.Data["lsPeak"];
                  
                }
            },
            async viewProduct(fileid) {
                this.loading(true);
                if (this.dataTableProduct != null) {
                    this.dataTableProduct.destroy();
                }
                const rs = await this.call({ Action: 'Inventory/ViewFile', Object: { FileID: fileid} })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    this.fileId = 0;
                    this.showOptions = false;
                } else {
                    this.dataProduct = rs.Data;
                    
                    this.changeView('product');
                }
            },
            changeView(view) {
                this.loading(true);
                if (this.dataTable != null) 
                    this.dataTable.destroy();               
                if (view == 'product') {
                    this.isViewProduct = true;
                    this.isViewForecast = false;
                    setTimeout(this.doTableProduct, 200);
                } else {
                    this.isViewProduct = false;
                    this.isViewForecast = true;
                   
                    setTimeout(this.doTableForecast, 200);
                }
            },
            doTableProduct() {
                if (this.checkFullAcl('Product/View')) {
                    this.dataTable = $('#datatableProduct').DataTable({
                        responsive: true,
                    });
                }
                this.loading(false);
            },
            doTableForecast() {
                
                //let fromd = this.selectedDate;
                let tod = this.monthSelected;
                let monthstr = tod.month + 1;
                let extra = '';
                if (this.forecastFilter != "both") extra = `(${this.forecastFilter})`;
                if (tod.month < 9) monthstr = "0" + (tod.month + 1)
                const reportTitle = `Sales Forecast report\n ${monthstr}-${tod.year}\n${extra}`;
                const _this = this;
                let buttons = [

                    {
                        extend: 'excelHtml5', text: 'Excel',
                        title: reportTitle,
                        className: 'btn btn-primary mb-3', footer: true,
                        exportOptions: {
                            modifier: {
                                page: 'all'
                            },
                            format: {

                            }
                        },
                        customize: function (xlsx) {
                            //https://jsfiddle.net/m26nx9yg/
                            var sheet = xlsx.xl.worksheets['sheet1.xml'];
                            var col = $('col', sheet);


                            $(col).attr('width', 20);



                            //cell style format reference:
                            //https://datatables.net/forums/discussion/52748/is-there-any-a-way-to-style-a-cell-in-excel-background-yellow-and-alignment-center-at-the-same-time
                            //https://stackoverflow.com/questions/67331618/jquery-datatable-excel-export-number-format/67340599#67340599
                            //http://officeopenxml.com/SSstyles.php
                            //https://datatables.net/reference/button/excelHtml5
                            //============== add style =========

                            // add a new "cellXfs" cell formatter, which uses our new number format (numFmt 176):
                            var celXfsElement = xlsx.xl['styles.xml'].getElementsByTagName('cellXfs');

                            //wrap and right style                                    
                            $(celXfsElement).append('<xf numFmtId="0" fontId="2" fillId="0" borderId="0" xfId="0" applyNumberFormat="0" applyFont="1" applyFill="0" applyBorder="0" applyAlignment="1"><alignment horizontal="right" wrapText="1" /></xf>');
                            var wrapStyleFormatId = $(celXfsElement).attr("count") * 1;
                            $(celXfsElement).attr("count", wrapStyleFormatId + 1); // increment the count




                            //https://datatables.net/forums/discussion/40038/bugreport-buttons-excel-currency-symbol-appearing-on-right
                            $(xlsx.xl["styles.xml"]).find('numFmt[numFmtId="164"]').attr('formatCode', '[$$-en-AU]#,##0;[Red]-[$$-en-AU]#,##0');
                        }
                    },

                    {
                        text: 'PDF',
                        className: 'btn btn-danger mb-3',

                        extend: 'pdfHtml5',
                        footer: true,
                        exportOptions: {
                            stripNewlines: false
                        },
                        title: reportTitle,
                        pageSize: 'A4',
                        orientation: 'portrait',

                        //https://stackoverflow.com/questions/47894339/align-one-column-with-pdfmake-and-datatables
                        customize: function (doc) {
                            
                            doc.styles.tableRed = { fillColor: '#ff9a9a' };
                            doc.styles.title = {
                                color: 'red',
                                fontSize: '40',
                                //background: 'blue',
                                alignment: 'center'
                            }
                            var rowCount = doc.content[1].table.body.length;
                            var colCount = 6;
                            for (let i = 1; i < rowCount; i++) {
                                doc.content[1].table.body[i][1].alignment = 'center';
                                doc.content[1].table.body[i][2].alignment = 'center';
                                doc.content[1].table.body[i][3].alignment = 'center';
                                doc.content[1].table.body[i][4].alignment = 'center';
                                doc.content[1].table.body[i][5].alignment = 'center';
                                
                                //check for shortage coloring
                                if (doc.content[1].table.body[i][5].text <= 0 && _this.forecastFilter != "shortage")
                                    for (let j = 0; j < colCount; j++)
                                        doc.content[1].table.body[i][j].style = 'tableRed';
                            }

                            doc.content[1].table.widths = [
                                '6%',
                                '29%',
                                '20%',
                                '15%',
                                '15%',
                                '15%',
                              
                            ];

                        }
                    },
                ];
                if (!this.checkAcl('Download'))
                    buttons = [];
                console.log(buttons);
                this.dataTable = $('#datatableForecast').DataTable({
                    responsive: true,
                    processing: true,
                    dom: 'Blfrtip',
                    buttons: buttons,
                    });
                
                this.loading(false);
            },
        },
        
        created() {
            
        },
        mounted() {            
            //if already login then start, 
            //else will watch isCheckedLogin to catch its change
            //to call start function
            if (this.isCheckedLogin) this.start();
        },
        watch: {
            //catch status from not login to logged in 
            //to call start function.
            //Apply for reload page issue
            isCheckedLogin(newVal) {
                console.log("user index newVal", newVal);
                if (newVal) {
                    this.start();
                }
            },
            forecastFilter() {
                this.doForecast();
            },
            monthSelected() {
                this.doForecast();
            }
        }
    };
</script>
